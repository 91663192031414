<template>
    <div class="ordercard" @click="goToDetail">
        <div class="card-top">
            <div class="left ">
                {{ time }}
            </div>
            <div class="right">
                {{ state }}
            </div>
        </div>
        <div class="card-con">
            <van-card
                    :num="num"
                    :price="price"
                    :desc="desc"
                    :title="title"
                    :thumb="thumb"
            />

        </div>
        <div class="card-foot">
            <div class="left txt-red">
                {{ orderStatus < 2 ? '请在' + payInfo + '前支付': '' }}
            </div>
            <div class="right">
                <van-button size="small" type="default" v-if="isObjects">查看物流</van-button>
                <van-button size="small" type="default">详情</van-button>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "OrderCard",
    props: {
      id: {
        type: Number,
        default: 0,
      },
      num: {
        type: Number,
        default: 0
      },
      price: {
        type: String,
        default: 0
      },
      desc: {   //商品描述
        type: String,
        default: ""
      },
      title: {  //商品标题
        type: String,
        default: ""
      },
      thumb: {  //商品图片
        type: String,
        default: ""
      },
      time: {
        type: String,
        default: ""
      },
      state: {
        type: String,
        default: ""
      },
      payInfo: {
        type: String,
        default: ""
      },
      isObjects: {  //是否实物订单
        type: Boolean,
        default: false
      },
      orderStatus: {
        type: Number,
        default: 0,
      }


    },
    methods: {
      goToDetail() {
        this.$router.push('/orderinfo/' + this.id)
      }
    },
    created() {
    }
  };
</script>

<style scoped lang="scss">
    .ordercard {
        margin: 10px 20px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 0 4px #ddd;

        .van-card {
            background: #fff;
        }

        .card-top {
            display: flex;
            justify-content: space-between;
            padding: 15px;
            border-bottom: 1px solid #efefef;
            font-size: 12px;
            align-items: center;
        }

        .card-foot {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 15px;
            font-size: 14px;
            border-top: 1px solid #efefef;

            .van-button {
                margin-left: 10px;
                border-radius: 4px;
            }
        }
    }

</style>
