<template>
    <div class="usercenter">
        <van-nav-bar title="个人中心" left-arrow @click-left="$router.push('/')"/>
        <div class="usertop">
            <div class="photo"><img :src="$store.getters.userinfo.avatar" alt=""></div>
            <div class="info">
                <div class="name"><span>{{ $store.getters.userinfo.nickName }}</span>
                    <van-button round size="mini" type="default" v-if="$store.getters.userinfo.userLevel <= 0"
                                @click="submitDr">成为团长
                    </van-button>
                    <p style="font-size: 12px;margin-left: 10px" v-else>{{$store.getters.userinfo.userLevel == 1 ? '小团长' : '大团长'}}</p>

                </div>
                <p class="time">加入时间：{{ $store.getters.userinfo.createTime }}</p>
            </div>
            <div class="add" v-if="$store.getters.userinfo.userLevel > 0" @click="$router.push('/qr')">
              <img src="../assets/code2.png" alt="">
              <span>发展团长码</span>
            </div>
        </div>
        <div class="usermenu">
            <!-- 用户中心导航 -->
            <div class="menubar">
                <van-tabbar :fixed=false v-model="active">
                    <van-tabbar-item @click="changeStatus(null)">
                        <span>全部订单</span>
                        <template #icon="props">
                            <img :src="props.active ? icon1.active : icon1.inactive" />
                        </template>
                    </van-tabbar-item>
                    <van-tabbar-item @click="changeStatus(0)">
                        <span>未支付</span>
                        <template #icon="props">
                            <img :src="props.active ? icon2.active : icon2.inactive" />
                        </template>
                    </van-tabbar-item>
                    <van-tabbar-item @click="changeStatus(2)">
                        <span>已支付</span>
                        <template #icon="props">
                            <img :src="props.active ? icon3.active : icon3.inactive" />
                        </template>
                    </van-tabbar-item>
                    <van-tabbar-item  @click="changeStatus(4)">
                        <span>已完成</span>
                        <template #icon="props">
                            <img :src="props.active ? icon4.active : icon4.inactive" />
                        </template>
                    </van-tabbar-item>
                    <van-tabbar-item replace to="/coffers" v-if="$store.getters.userinfo.userLevel > 0">
                        <span>我的金库</span>
                        <template #icon="props">
                            <img :src="props.active ? icon4.active : icon4.inactive" />
                        </template>
                    </van-tabbar-item>
                </van-tabbar>
            </div>
        </div>
        <!-- 用户中心订单列表 -->
        <div class="orderlist" style="margin-bottom: 100px">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh" direction="up">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                    <order-card v-for="item in list" :key="item.id" v-bind="item"></order-card>
                </van-list>
            </van-pull-refresh>
        </div>
        <!-- 底部导航 -->
        <bottom-bar/>

    </div>
</template>

<script>
  import BottomBar from "@/components/BottomBar.vue";
  import MenuBar from "@/components/MenuBar.vue";
  import OrderCard from "@/components/OrderCard.vue";
  import {orderList} from "@/api/order";


  export default {
    name: "usercenter",
    data () {
      return {
        active: 0,
        loading: false,
        finished: false,
        refreshing: false,
        pageNum: 1,
        pages: 1,
        list: [],
        status: null,
        icon1: {
          active: require("@/assets/user1-2.svg"),
          inactive: require("@/assets/user1-1.svg"),
        },
        icon2: {
          active: require("@/assets/user2-2.svg"),
          inactive: require("@/assets/user2-1.svg"),
        },
        icon3: {
          active: require("@/assets/user3-2.svg"),
          inactive: require("@/assets/user3-1.svg"),
        },
        icon4: {
          active: require("@/assets/user4-2.svg"),
          inactive: require("@/assets/user4-1.svg"),
        },
        icon5: {
          active: require("@/assets/user5-2.svg"),
          inactive: require("@/assets/user5-1.svg"),
        },
      }
    },
    methods: {
      loadOrderList () {
        if (this.pageNum > this.pages) {
          this.loading = false;
          return
        }
        orderList({
          status: this.status,
          pageNum: this.pageNum,
        }).then(data => {
          if (data.success == true) {
            this.pageNum = data.data.current + 1;
            this.pages = data.data.pages;
            this.list.push(...this.handleData(data.data.records));
            if (this.pageNum > this.pages) {
              this.finished = true;
            }
          }
        }).finally(() => {
          this.loading = false;
        }).catch(() => {
          this.loading = false;
          this.finished = true;
        })
      },
      handleData(items) {
        const data = [];
        items.forEach(item => {
          data.push({
            id: item.id,
            num: item.num,
            price: parseFloat(item.price, 2).toFixed(2),
            desc: item.name,
            title: item.productTitle,
            thumb: item.productImgList[0],
            time: item.createTime,
            state: item.orderStatusText,
            payInfo: item.payEndTime,
            orderStatus: item.orderStatus,
          })
        });
        return data
      },
      onLoad () {
        if (this.refreshing) {
          this.list = [];
          this.pageNum = 1;
          this.pages = 1;
          this.refreshing = false;
        }
        this.loadOrderList()
      },
      onRefresh () {
        // 清空列表数据
        this.finished = false;

        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.onLoad();
      },
      changeStatus(status) {
        this.status = status;
        this.refreshing = true;
        this.onRefresh()
      },
      submitDr () {
        this.$router.push('/tuan')
      }
    },
    components: {
      'bottom-bar': BottomBar,
      'menu-bar': MenuBar,
      'order-card': OrderCard
    },
    created () {
    }
  };
</script>
<style lang="scss">
    .menubar {
        margin: 0 20px 20px 20px;
        padding: 10px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 0 4px #ddd;
    }
    .usercenter {
        .usertop {
            position: relative;
            padding: 20px;
            display: flex;
            justify-content: flex-start;
            color: #fff;
            background: #D50606;

            .add {
              position: absolute;
              right: 15px;
              top: 30px;

              img,
              span {
                display: block;
              }

              img {
                width: 24px;
                height: 24px;
                margin: 0 auto;
              }

              span {
                color: #fff;
                padding-top: 5px;
                font-size: 10px;
                text-align: center;
              }
            }

            .photo {
                margin-right: 10px;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                border: 2px solid #fff;
                overflow: hidden;

                img {
                    width: 100%;
                }
            }

            .info {
                .name {
                    font-size: 16px;
                    padding: 8px 0;
                    display: flex;
                    align-items: center;

                    .van-button {
                        margin-left: 10px;
                    }
                }

                .time {
                    font-size: 12px;
                }
            }
        }

        .van-hairline--top-bottom::after {
            border: 0;
        }

        .usermenu {
            width: 100%;
            content: "";
            display: block;
            margin-bottom: 35px;
            height: 50px;
            background: #D50606;
            border-radius: 0px 0px 100px 100px;
        }

    }
</style>
