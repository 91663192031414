<template>
    <div class="menubar">
        <van-tabbar route :fixed=false>
            <van-tabbar-item replace to="/usercenter">
                <span>全部订单</span>
                <template #icon="props">
                    <img :src="props.active ? icon1.active : icon1.inactive"/>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/usercenterunpaid">
                <span>未支付</span>
                <template #icon="props">
                    <img :src="props.active ? icon2.active : icon2.inactive"/>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/usercenterpaid">
                <span>已支付</span>
                <template #icon="props">
                    <img :src="props.active ? icon3.active : icon3.inactive"/>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/usercentercompleted">
                <span>已完成</span>
                <template #icon="props">
                    <img :src="props.active ? icon4.active : icon4.inactive"/>
                </template>
            </van-tabbar-item>
            <van-tabbar-item replace to="/coffers">
                <span>我的金库</span>
                <template #icon="props">
                    <img :src="props.active ? icon4.active : icon4.inactive"/>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
  export default {
    name: 'BottomBar',
    data: function () {
      return {
        icon1: {
          active: require("@/assets/user1-2.svg"),
          inactive: require("@/assets/user1-1.svg"),
        },
        icon2: {
          active: require("@/assets/user2-2.svg"),
          inactive: require("@/assets/user2-1.svg"),
        },
        icon3: {
          active: require("@/assets/user3-2.svg"),
          inactive: require("@/assets/user3-1.svg"),
        },
        icon4: {
          active: require("@/assets/user4-2.svg"),
          inactive: require("@/assets/user4-1.svg"),
        },
        icon5: {
          active: require("@/assets/user5-2.svg"),
          inactive: require("@/assets/user5-1.svg"),
        },
      }
    },
    props: {},
    methods: {}
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .menubar {
        margin: 0 20px 20px 20px;
        padding: 10px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 0 4px #ddd;
    }
</style>
