import request from "@/utils/request";


// 订单列表
export function orderList (data) {
  return request({
    url: '/o/l',
    method: 'post',
    data
  })
}

// 生成订单
export function createOrder (params) {
  return request({
    url: '/o/uc',
    method: 'post',
    data: params
  })
}

// 获取订单详情
export function getOrderInfo (params) {
  return request({
    url: '/o/s',
    method: 'post',
    data: params
  })
}

// 订单支付
export function orderPay (params) {
  return request({
    url: '/p/c',
    method: 'post',
    data: params
  })
}

// 商品核销
export function userCode(params) {
  return request({
    url: '/wor/h',
    method: 'post',
    data: params
  })
}


// 商家核销信息
export function hexiaoInfo() {
  return request({
    url: '/wor/i',
    method: 'post'
  })
}

// 核销记录
export function hexiaoRecords (params) {
  return request({
    url: '/wor/l',
    method: 'post',
    data: params,
  })
}
